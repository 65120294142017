import { PageProps } from "gatsby";
import React from "react";

import { HandwrittenSheetMusic } from "../components/pages/HandwrittenSheetMusic";

import { snProps } from "../js/utils";

const HandwrittenSheetMusicPage = (props: PageProps) => (
  <HandwrittenSheetMusic {...{ ...snProps, ...props }} />
);

export default HandwrittenSheetMusicPage;
