import React from "react";
import { Link, withPrefix } from "gatsby";
import Layout from "../parts/Layout";
import { PageComponentProps } from "../../types/types";

import * as pageStyles from "../global-styles/page.module.css";

export const HandwrittenSheetMusic = (props: PageComponentProps) => {
  const { clairnoteName, otherUrlDir, sn, urlDir } = props;
  const title = "Handwritten Sheet Music";
  const description = `Examples of handwritten sheet music in ${clairnoteName} music notation.`;
  return (
    <Layout title={title} description={description} {...props}>
      <article className="page">
        <h1 className={`title is-3 ${pageStyles.pageTitle}`}>{title}</h1>
        <div>
          {sn ? (
            <>
              <p>
                Currently there are only examples of{" "}
                <Link to={otherUrlDir + "handwritten-sheet-music/"}>
                  handwritten sheet music
                </Link>{" "}
                for <Link to={otherUrlDir}>Clairnote DN</Link> and not{" "}
                <Link to={urlDir}>Clairnote SN</Link>.
              </p>
            </>
          ) : (
            <>
              <p>
                {clairnoteName} music notation is easy to write by hand. Below
                are links to some examples of handwritten {clairnoteName} sheet
                music. They have been generously contributed by a{" "}
                {clairnoteName} user and are mostly music for guitar. All of the
                works are in the public domain and freely available in PDF
                format.
              </p>
              <p>
                <strong>J. S. Bach</strong>
              </p>
              <ul>
                <li>
                  <a
                    href={withPrefix(
                      "pdf/handwritten/dn/JSBach-bwv1002-double.pdf"
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    JSBach-bwv1002-double
                  </a>{" "}
                  – the third Double taken from Partita No. 1 for solo violin
                  (BWV 1002)
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={withPrefix(
                      "pdf/handwritten/dn/12-kleine-Praludien-no-1.pdf"
                    )}
                  >
                    12 Kleine Praludien no 1
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={withPrefix(
                      "pdf/handwritten/dn/sarabande-guitar-Bach.pdf"
                    )}
                  >
                    Sarabande (guitar)
                  </a>
                </li>
              </ul>
              <p>
                <strong>T. Campion</strong>
              </p>
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={withPrefix("pdf/handwritten/dn/when-to-her-lute.pdf")}
                  >
                    When to Her Lute...
                  </a>
                </li>
              </ul>
              <p>
                <strong>M. Giuliani</strong>
              </p>
              <ul>
                <li>
                  <a
                    href={withPrefix(
                      "pdf/handwritten/dn/Giuliani-andantino.pdf"
                    )}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Andantino
                  </a>
                </li>
              </ul>
              <p>
                <strong>C. Gounod</strong>
              </p>
              <ul>
                <li>
                  <a
                    href={withPrefix(
                      "pdf/handwritten/dn/GounodC-funeral-march-marionette.pdf"
                    )}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Funeral March of a Marionette
                  </a>
                </li>
              </ul>
              <p>
                <strong>H. Lawes</strong>
              </p>
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={withPrefix("pdf/handwritten/dn/Delicate-Beauty.pdf")}
                  >
                    Delicate Beauty
                  </a>
                </li>
              </ul>
              <p>
                <strong>D. Kellner</strong>
              </p>
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={withPrefix(
                      "pdf/handwritten/dn/Courante-guitar-Kellner.pdf"
                    )}
                  >
                    Courante (guitar)
                  </a>
                </li>
              </ul>
              <p>
                <strong>F. Sor</strong>
              </p>
              <ul>
                <li>
                  <a
                    href={withPrefix("pdf/handwritten/dn/Sor-ex-20.pdf")}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sor-ex-20
                  </a>
                </li>
                <li>
                  <a
                    href={withPrefix("pdf/handwritten/dn/Sor-lesson.pdf")}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sor-lesson
                  </a>
                </li>
                <li>
                  <a
                    href={withPrefix("pdf/handwritten/dn/Sor-opus-31.pdf")}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sor-opus-31
                  </a>
                </li>
                <li>
                  <a
                    href={withPrefix(
                      "pdf/handwritten/dn/Sor-opus-31-study-Bm.pdf"
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sor-opus-31-study-Bm
                  </a>
                </li>
                <li>
                  <a
                    href={withPrefix(
                      "pdf/handwritten/dn/Sor-prière-religieuse.pdf"
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sor-priere-religieuse
                  </a>
                </li>
              </ul>
              <p>
                <strong>R. Visee</strong>
              </p>
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={withPrefix(
                      "pdf/handwritten/dn/Tombeau-de-M-Mouton.pdf"
                    )}
                  >
                    Tombeau de M Mouton
                  </a>
                </li>
              </ul>
              <p>
                <strong>Anonymous Works</strong>
              </p>
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={withPrefix(
                      "pdf/handwritten/dn/Anonymous-Fantasia-renaissance.pdf"
                    )}
                  >
                    Anonymous-Fantasia-renaissance
                  </a>
                </li>
              </ul>
              <p>
                <strong>
                  Traditional Works
                  <br />
                </strong>
              </p>
              <ul>
                <li>
                  <a
                    href={withPrefix(
                      "pdf/handwritten/dn/Traditional-Killavil-Jig-Hags-Purse.pdf"
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Traditional-Killavil-Jig-Hags-Purse
                  </a>
                </li>
                <li>
                  <a
                    href={withPrefix(
                      "pdf/handwritten/dn/Traditional-Mazurka-des-Escoliers.pdf"
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Traditional-Mazurka-des-Escoliers
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={withPrefix(
                      "pdf/handwritten/dn/Traditional-Chief-O-Neills-hornpipe.pdf"
                    )}
                  >
                    Traditional-Chief-O-Neills-hornpipe
                  </a>
                </li>
              </ul>
              <p>
                Create your own handwritten sheet music using{" "}
                <Link
                  title="Blank Staff Paper"
                  to={urlDir + "blank-staff-paper/"}
                >
                  blank staff paper
                </Link>{" "}
                or check out the{" "}
                <Link title="Sheet Music Library" to={urlDir + "sheet-music"}>
                  sheet music
                </Link>{" "}
                collection which offers free sheet music engraved by LilyPond
                software.
              </p>
            </>
          )}
        </div>
      </article>
    </Layout>
  );
};
